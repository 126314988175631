import { keyframes, css } from "@emotion/core"
import styled from "@emotion/styled"

const wave = keyframes`
  0% {
    d: path("M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  50% {
    d: path("M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  100% {
    d: path("M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
`

const upDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(30px);
  }
`

const upDownWide = keyframes`
  from {
    transform: translateX(0) translateY(0);
  }
  to {
    transform: translateX(100px) translateY(200px);
  }
`

const upDownWideRight = keyframes`
  from {
    transform: translateX(0) translateY(0);
  }
  to {
    transform: translateX(100px) translateY(200px);
  }
`

const upDownWideLeft = keyframes`
  from {
    transform: translateX(0) translateY(0);
  }
  to {
    transform: translateX(-100px) translateY(-200px);
  }
`

const upDownAnimation = css`
  ${upDown} 4s ease-in-out infinite alternate;
`

const upDownWideAnimation = css`
  ${upDownWide} 18s ease-in-out infinite alternate;
`

const upDownWideRightAnimation = css`
  ${upDownWideRight} 18s ease-in-out infinite alternate;
`

const upDownWideLeftAnimation = css`
  ${upDownWideLeft} 18s ease-in-out infinite alternate;
`


export const UpDown = styled.div`
  animation: ${upDownAnimation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const UpDownWide = styled.div`
  animation: ${upDownWideAnimation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const UpDownWideRight = styled.div`
  animation: ${upDownWideRightAnimation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const UpDownWideLeft = styled.div`
  animation: ${upDownWideLeftAnimation};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const waveAnimation = (length: string) => css`
  animation: ${wave} ${length} linear infinite alternate;
`