import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Nightlife" link="/projects/nightlife" bg="linear-gradient(to right, #171762 0%, #5C3CC5 100%)" mdxType="ProjectCard">
The Nightlife app connects you with the latest happy hours, drink specials, concerts, and events.
    </ProjectCard>
    <ProjectCard title="TrackVia" link="/projects/trackvia" bg="linear-gradient(to right, #11998e 0%, #38ef7d 100%)" mdxType="ProjectCard">
TrackVia helps businesses rapidly digitize and streamline their critical operational workflows and field processes with low-code web and mobile applications.
    </ProjectCard>
    <ProjectCard title="Copycat" link="/projects/copycat" bg="linear-gradient(to right, #5A4DFD 0%, #65DDF6 100%)" mdxType="ProjectCard">
Automate your copy and paste workflow on Mac with Copycat.
    </ProjectCard>
    <ProjectCard title="Who's on Tap" link="/projects/wot" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Connect freelancers and self-employed members of the gig economy to tap into the service industry in an on-demand fashion.
    </ProjectCard>
    <ProjectCard title="KC Bloom Co" link="/projects/kcbloomco" bg="linear-gradient(to right, #C06C84 0%, #6C5B7B 50%, #355C7D 100%)" mdxType="ProjectCard">
A mobile + social flower experience: Volkswagen van Westy brings blooms to your favorite Kansas City hot spots.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      